// import all css first
import "../public-dynamic/stylsheets/app.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
//
import { AppFlavor } from ":src/modules/whitelabel";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

import {
  LandingRouter,
  AdminRouter,
  routers$admin,
  AuthRouter,
  routers$auth,
  MonitorRouter,
  routers$monitor,
  PlayRouter,
  routers$play,
} from "./routers";

import { Router } from "./components/route";
export default function EntryClient() {
  return (
    <>
      {/* portals */}
      <AppFlavor />
      {/* routers and pages */}
      <Router
        root={<LandingRouter />}
        bases={{
          [routers$admin.routes.BASE]: <AdminRouter />,
          [routers$auth.routes.BASE]: <AuthRouter />,
          [routers$monitor.routes.BASE]: <MonitorRouter />,
          [routers$play.routes.BASE]: <PlayRouter />,
        }}
      />
    </>
  );
}
